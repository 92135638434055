import { CgClose } from "react-icons/cg";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "./Button";

function Component({
  width = "450px",
  isOpen,
  toggle,
  title,
  onClick,
  children,
  okLabel = "Yes",
  cancelLabel = "",
  disableHeader = false,
  disableFooter = false,
  onClickSecondary = null,
  disablePrimary = false,
  disabledOk = false,
  disableSecondary = false,
  subtitle = "",
  btnDanger = false,
  className,
  nameval,
  closeTrigger,
  ...props
}) {
  const closeBtn = (
    <div
      onClick={toggle}
      className={`bg-gray-200 p-2 rounded-lg hover:bg-red-600 hover:text-white transition-all cursor-pointer ${
        subtitle ? "-mt-6" : ""
      } `}
    >
      <CgClose />
    </div>
  );
  const { values } = props;
  const [disablePrimaryBtn, setDisablePrimaryBtn] = useState(disabledOk);

  useEffect(() => {
    if (nameval == "update_reason") {
      setDisablePrimaryBtn(
        values !== undefined ? (values[nameval] ? false : true) : true
      );
    } else {
      setDisablePrimaryBtn(disabledOk);
    }
  }, [values]);

  return (
    <div>
      <Modal
        className={className}
        isOpen={isOpen}
        scrollable={false}
        modalTransition={{ timeout: 1 }}
        backdropTransition={{ timeout: 1 }}
        toggle={toggle}
        style={{
          maxWidth: width,
        }}
      >
        {!disableHeader && (
          <ModalHeader
            toggle={toggle}
            close={closeTrigger ? closeTrigger : closeBtn}
            className={`w-full flex justify-between px-4 mt-2 ${
              subtitle ? "" : "-mb-5"
            }`}
          >
            <h3 className="text-[20px] font-bold">{title}</h3>
            <h6 className="text-[12px] font-light">{subtitle}</h6>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
        {!disableFooter && (
          <ModalFooter className="">
            {!disableSecondary && (
              // <div
              //   onClick={onClickSecondary !== null ? onClickSecondary : toggle}
              //   className="border-[1px] border-red-600 hover:bg-red-600 hover:text-white text-red-600 px-5 py-2 rounded-lg text-[13px] cursor-pointer transition-all"
              // >
              //   {cancelLabel === "" ? "Cancel" : cancelLabel}
              // </div>
              <Button
                color={"secondary"}
                onClick={onClickSecondary !== null ? onClickSecondary : toggle}
                label={cancelLabel === "" ? "Cancel" : cancelLabel}
              />
            )}
            {!disablePrimary && (
              // <div
              //   onClick={onClick}
              //   className="border-[1px] border-red-600 bg-red-600 text-white px-5 py-2 rounded-lg text-[13px] cursor-pointer transition-all"
              // >
              //   {okLabel}
              // </div>
              <Button
                disabled={disablePrimaryBtn}
                color={btnDanger ? "warning" : ""}
                onClick={onClick}
                label={okLabel}
              />
            )}{" "}
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
}

export default Component;
