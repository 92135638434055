import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toSentenceCase } from "../../utils";
import { endpointAdmin, endpointBookingFacility } from "../../settings";
import { deleteFacility } from "../slices/facility";
import { setSelected } from "../slices/facility";
import { FiPlus } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { Form, Formik } from "formik";
import { get } from "../slice";
import Table from "../../components/Table";
import Breadcrumb from "../../components/Breadcrumb";
import Pill from "../../components/Pill";
import Facilities from "../../components/cells/Facilities";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Filter from "../../components/Filter";
import Modal from "../../components/Modal";

function Component({ view, title = "", pagetitle, canAdd, canDelete }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [building, setBuilding] = useState("");
  const [toggle, setToggle] = useState(false);
  const { role } = useSelector((state) => state.auth);
  const { refreshToggle } = useSelector((state) => state.facility);
  const { auth } = useSelector((state) => state);
  const [buildingLabel, setBuildingLabel] = useState("");
  const [buildingList, setBuildingList] = useState("");
  const [modalDeleteFacility, setModalDeleteFacility] = useState(false);

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);

  let dispatch = useDispatch();
  let history = useHistory();
  let { url } = useRouteMatch();

  const facilityColumns = [
    {
      Header: "ID",
      accessor: (row) => (
        <div>
          <Tooltip id="facility-id" />
          <p
            data-tooltip-id="facility-id"
            data-tooltip-content={row.id}
            className="max-w-[3rem] uppercase"
          >
            {row.id.slice(0, 7)}
          </p>
        </div>
      ),
    },
    {
      id: "facility-list",
      Header: "Facility Name",
      accessor: (row) => {
        return (
          <div className="max-w-[12rem]">
            {" "}
            <Facilities id={row.id} data={row} items={[row.name]} />
          </div>
        );
      },
    },
    {
      Header: "Building Name",
      accessor: (row) => <div className="capitalize">{row.building}</div>,
    },
    {
      Header: "Status",
      accessor: (row) => {
        return row.status === "close" ? (
          <Pill color="secondary">{toSentenceCase(row.status)}</Pill>
        ) : (
          <Pill color="success">{toSentenceCase(row.status)}</Pill>
        );
      },
    },
  ];

  useEffect(() => {
    (!search || search.length >= 1) &&
      dispatch(
        get(
          endpointAdmin +
            "/building" +
            "?limit=" +
            limit +
            "&page=1" +
            "&search=" +
            search,
          (res) => {
            let data = res.data.data.items;
            let totalItems = Number(res.data.data.total_items);
            let restTotal = totalItems - data.length;

            let formatted = data.map((el) => ({
              label: el.name,
              value: el.id,
            }));

            if (data.length < totalItems && search.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setBuildingList(formatted);
          }
        )
      );
  }, [dispatch, search, limit]);

  return (
    <>
      {/* Modal Facility Delete */}
      <Modal
        isOpen={!!modalDeleteFacility}
        toggle={() => setModalDeleteFacility(false)}
        title={"Delete Facility"}
        disableFooter={true}
      >
        <Formik
          initialValues={{ name: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            dispatch(deleteFacility(modalDeleteFacility.id));
            setModalDeleteFacility(false);
          }}
        >
          {(props) => {
            return (
              <Form>
                <div className="px-2">
                  <p className="py-12">
                    Are you sure you want to delete this facility?
                  </p>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <Button
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => setModalDeleteFacility(false)}
                    color={"secondary"}
                  />
                  <Button label={"Delete"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Breadcrumb title={title} />
      <h2 className="PageTitle">Facility List</h2>
      <div className="Container">
        <Table
          columns={facilityColumns}
          data={data?.items || []}
          fetchData={useCallback(
            (page, limit, searchItem) => {
              role !== "sa"
                ? setBuilding(auth.building_id)
                : setBuilding(building);
              setLoading(true);
              dispatch(
                get(
                  endpointBookingFacility +
                    "/admin/facilities" +
                    "?building_id=" +
                    building +
                    "&search=" +
                    searchItem +
                    "&limit=" +
                    limit +
                    "&page=" +
                    (page + 1),
                  (res) => {
                    setData(res.data.data);
                  }
                ),
                setLoading(false)
              );
            },
            [dispatch, building, toggle, refreshToggle]
          )}
          loading={loading}
          onClickDelete={
            canDelete
              ? (row) => {
                  setModalDeleteFacility(row);
                }
              : null
          }
          pageCount={data?.total_page}
          totalItems={data?.total_item}
          actionDownloads={
            canAdd
              ? [
                  <Button
                    key="Add Facilities"
                    label="Add Facilities"
                    icon={<FiPlus />}
                    onClick={() => {
                      dispatch(setSelected({}));
                      history.push(url + "/add");
                    }}
                  />,
                ]
              : []
          }
          filters={
            role == "sa"
              ? [
                  {
                    label: "Building: ",
                    value: building ? buildingLabel : "All",
                    hidex: building === "",
                    delete: () => setBuilding(""),
                    component: (toggleModal) => (
                      <>
                        <Input
                          label="Search Building"
                          compact
                          icon={<FiSearch />}
                          inputValue={search}
                          setInputValue={setSearch}
                        />
                        <Filter
                          data={buildingList}
                          onClick={(el) => {
                            if (!el.value) {
                              setLimit(limit + el.restTotal);
                              return;
                            }
                            setBuilding(el.value);
                            setBuildingLabel(el.label);
                            setLimit(5);
                            toggleModal(false);
                          }}
                          onClickAll={() => {
                            setBuilding("");
                            setBuildingLabel("");
                            setLimit(5);
                            toggleModal(false);
                          }}
                        />
                      </>
                    ),
                  },
                ]
              : []
          }
        />
      </div>
    </>
  );
}

export default Component;
