import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { defaultRole } from "./settings";

import "./App.css";
import "./features/auth/styles.css";
import "./components/styles.css";
import "./components/input.css";
import "./components/table.css";
import NotFound from "./components/NotFound";

import Login from "./features/auth/Login";
import OTP from "./features/auth/OTP";

import Editor from "./features/roles/Editor";
import MerchantAcquisition from "./features/roles/MerchantAcquisition";
import Viewer from "./features/roles/Viewer";
import Finance from "./features/roles/Finance";
import VASSales from "./features/roles/VASSales";
import VASAdvertiser from "./features/roles/VASAdvertiser";
import BM from "./features/roles/BM";

import { store, persistor } from "./store";
import { setResidentFilter } from "./features/slices/resident";
import { setDateFilter, setTaskFilter } from "./features/slices/task";
import { setBillingFilter } from "./features/slices/billing";
import { Toaster } from "sonner";

function SA({ children, ...other }) {
  const { user } = useSelector((state) => state.auth);
  const { billingFilter } = useSelector((state) => state.billing);

  let dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    if (path !== "task") {
      dispatch(setTaskFilter({}));
      dispatch(setDateFilter({}));
    }
    if (path !== "resident") {
      dispatch(setResidentFilter({}));
    }
    if (path !== "billing") {
      dispatch(setBillingFilter({}));
    }
  }, [location]);

  switch (user.group) {
    case "merchant_acquisition":
      return <MerchantAcquisition />;
    case "viewer":
      return <Viewer />;
    case "finance":
      return <Finance />;
    case "vas_sales":
      return <VASSales />;
    case "vas_advertiser":
      return <VASAdvertiser />;
    default:
      return <Editor />;
  }
}

function MainRoute({ children, ...other }) {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const { path } = other;

  return (
    <Route
      {...other}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: path + "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function OTPRoute({ children, ...other }) {
  const { email } = useSelector((state) => state.auth);

  const { path } = other;

  return (
    <Route
      {...other}
      render={({ location }) =>
        email ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: path + "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function AppRoute() {
  return (
    <Router>
      <div className="App" id="App">
        {/* <div className="App scroller" id="App"> */}
        <Switch>
          <Redirect exact from="/" to={"/" + defaultRole} />
          {/* superadmin routes */}
          {defaultRole === "sa" && (
            <Route path="/sa/login">
              <Login role="sa" />
            </Route>
          )}
          {defaultRole === "sa" && (
            <OTPRoute path="/sa/otp">
              <OTP role="sa" />
            </OTPRoute>
          )}
          {defaultRole === "sa" && (
            <MainRoute path="/sa">
              <SA />
            </MainRoute>
          )}
          {/* bm routes */}
          {defaultRole === "bm" && (
            <Route path="/bm/login">
              <Login role="bm" />
            </Route>
          )}
          {defaultRole === "bm" && (
            <OTPRoute path="/bm/otp">
              <OTP role="bm" />
            </OTPRoute>
          )}
          {defaultRole === "bm" && (
            <MainRoute path="/bm">
              <BM />
            </MainRoute>
          )}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster position="top-right" richColors expand={false} />
        <AppRoute />
      </PersistGate>
    </Provider>
  );
}

export default App;
